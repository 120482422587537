@import '../../styles/_variables';

.error-message {
  color: $red-rufous-base;
  background: $pink-extra;
  padding: 8px 16px;
  border-left: 4px solid $red-rufous-base;
}

.warning-message {
  color: $yellow;
  background: $melon-extra;
  padding: 8px 16px;
  border-left: 4px solid $yellow;
}

.error-message-text {
  color: $red-rufous-base;
}

.error-message-input {
  border: 1px solid $red-rufous-base !important;
}
