@import '../../styles/_variables';

.modal-content {
  &__checkbox-container {
    position: absolute;
    width: 205px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    background: $clear-white;
    box-shadow: 0 4px 4px $black-25;
    border-radius: 4px;
    padding: 15px 20px;
    z-index: 1000;
  }
}

.supplement-of-day-container {
  display: flex;
  gap: 32px;

  &__select {
    display: flex;
    gap: 14px;
    width: 100%;
  }
}

.delete-day-button {
  padding: 8px 16px;
  height: 44px;
  border: 1px solid $black-16;
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
  appearance: none;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: left;

  &:disabled {
    border: none;
    padding: 0;
    color: $black-87;
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .supplement-of-day-container {
    flex-wrap: wrap;
  }
}
