@import '../../../styles/_variables';

.profile-about {
  margin-top: 32px;

  &__title-container {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
  }

  &__about-tooltip {
    width: fit-content;
    transform: translate(50%, -10%);

    &__icon {
      height: 24px;
      color: $black-32;
    }
  }

  &__photo-info {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 16px;

    &__choose {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      &__button {
        font-size: 16px;
        line-height: 20px;
        text-decoration: underline;
        color: $extra-blue;
        background: transparent;
        border: none;
        cursor: pointer;
      }

      &__label {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__req {
        font-size: 12px;
        line-height: 14px;
        color: $black-64;
      }

      &__active {
        flex-direction: row;
        align-items: center;
      }

      input {
        display: none;
      }
    }

    &__info {
      position: relative;
      flex-grow: 1;

      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &__photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $extra-blue-small-opacity;
    border-radius: 50%;
    width: 72px;
    height: 72px;

    &__choose-template {
      color: $extra-blue;
      border-radius: 50%;
      width: 72px;
      height: 72px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 50%;
        width: 72px;
        height: 72px;
      }
    }

    input {
      display: none;
    }

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $extra-blue;
    }
  }

  &__description {
    margin-top: 16px;

    span {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__professional-contacts {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-column-gap: 16px;
    margin-top: 24px;
  }

  &__professional,
  &__contacts {
    display: flex;
    flex-direction: column;

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &__desc {
      font-size: 14px;
      line-height: 16px;
      color: $black-72;
      margin: 16px 0;
    }
  }

  &__banner {
    margin-top: 32px;

    &__header {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 22px;

      label {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-size: 12px;
        line-height: 10px;
      }

      &__fact-title {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
      }
    }
  }

  &__specializations {
    position: relative;
    cursor: pointer;
    display: flex;
    background: white;
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    outline: 1px solid $black-16;
    width: 100%;
    height: 44px;

    &__list {
      position: absolute;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 8px;
      column-gap: 16px;
      margin-top: 6px;
      background: $clear-white;
      box-shadow: 0 4px 8px $black-25;
      border-radius: 4px;
      padding: 8px 16px;
      z-index: 1;
      width: 100%;
    }

    &__chevron {
      position: absolute;
      top: 10px;
      right: 14px;
      height: 24px;
      color: $black-64;
    }
  }
}

@media screen and (max-width: 768px) {
  .profile-about {
    &__title {
      font-size: 18px;
      line-height: 21px;
    }

    &__professional-contacts {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
    }

    &__professional,
    &__contacts {
      &__title {
        font-size: 14px;
        line-height: 16px;
      }

      &__desc {
        font-size: 14px;
        line-height: 20px;
        margin: 8px 0 16px 0;
      }
    }

    &__about-tooltip {
      width: fit-content;
      transform: translate(90%, -10%);

      &__icon {
        height: 20px;
        width: 20px;

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &__photo-info {
      flex-wrap: wrap;

      &__choose {
        flex-grow: 1;
      }

      &__info {
        width: 100%;
      }
    }

    &__description {
      margin-top: 8px;
    }

    &__banner {
      &__body {
        gap: 8px;
      }
    }
  }
}
