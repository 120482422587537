@import '../../styles/_variables';

.file-upload-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: $black-48;
  color: $clear-white;
  padding: 24px;
  z-index: 5;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    outline: 8px dashed $clear-white;
    height: 100%;

    &__title,
    &__text {
      text-align: center;
    }

    &__title {
      font-weight: 500;
      font-size: 44px;
      line-height: 52px;
    }

    &__text {
      display: flex;
      align-items: center;
      gap: 24px;
      font-size: 32px;
      line-height: 38px;

      button {
        background: $clear-white;
      }
    }

    &__upload-button {
      overflow: hidden;
      border: 2px solid $extra-blue;
      border-radius: 40px;
      background: $clear-white;
      color: $extra-blue;
      cursor: pointer;
      padding: 0 16px;
      height: 44px;
      width: 138px;
      font-size: 16px;
    }
  }
}

.file-upload-button {
  position: relative;
  overflow: hidden;
}
.file-upload-button input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  color: transparent;
  cursor: inherit;
}
