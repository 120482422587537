@import '../../../styles/_variables';

.clients-container {
  &__header {
    display: flex;
    align-items: flex-end;
    gap: 32px;
    padding: 20px 24px;

    &__label {
      position: relative;
      width: 100%;
      max-width: 455px;
      margin-left: auto;

      svg {
        position: absolute;
        color: $black-32;
        right: 16px;
        top: 10px;
      }
    }

    &__search {
      padding: 10px 16px;
      width: 100%;
      height: 44px;
      border: none;
      outline: 2px solid $black-32;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;

      &:focus {
        outline: 2px solid $extra-blue;
      }

      &:focus + .clients-container__header__label__search-icon svg {
        color: $extra-blue;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1.5fr 2.5fr 1.5fr 0.75fr 0.75fr;

    &__header,
    &__card {
      display: grid;
      grid-template-columns: 3fr 1fr 1fr 1.5fr 2.5fr 1.5fr 0.75fr 0.75fr;
      grid-column-start: 1;
      grid-column-end: 9;
      align-items: center;
    }

    &__header {
      font-size: 14px;
      line-height: 20px;

      &__city,
      &__column {
        &__img {
          width: 12px;
          height: 8px;
          margin-left: 4px;
          margin-bottom: 1px;
          opacity: 0.16;
        }
      }

      &__city {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &__column {
        padding-right: 8px;
        white-space: nowrap;

        &:first-child {
          padding-left: 32px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          text-align: right;
        }

        &__tag-filter {
          cursor: pointer;
          background: transparent;
          border: 4.5px solid transparent;
          border-top: 4.5px solid $black-87;
          padding-top: 3px;
          margin-left: 3px;
        }

        &__empty-list {
          margin-top: 16px;
          color: $black-32;
          grid-column: 1/-1;
          text-align: center;
        }
      }
    }

    &__card {
      padding: 16px 0;
      font-size: 14px;
      line-height: 16px;
      border-bottom: 1px solid $black-16;

      &__tags {
        position: relative;

        button {
          &:focus {
            outline: none;
          }
        }
      }

      &__tags p {
        font-size: 14px;
        line-height: 16px;
        color: $black-64;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 56px;
        text-align: left;
      }

      &__add-tag-button {
        color: $extra-blue !important;
        width: 20px;
        height: 20px;
      }

      &__edit {
        svg {
          color: $black-32;
        }
      }

      &__trash,
      &__no-date {
        color: $red-rufous-base;
      }

      &__column {
        padding-right: 8px;

        &:first-child {
          display: flex;
          flex-direction: column;
          gap: 8px;
          font-size: 16px;
          line-height: 20px;
          padding-left: 32px;
        }

        &:nth-child(3) {
          white-space: nowrap;
        }

        &:nth-child(5),
        &:nth-child(6) {
          text-align: right;
        }

        span {
          white-space: nowrap;

          &:first-child {
            font-weight: 500;
          }
        }
      }

      button {
        border: none;
        background: transparent;
        cursor: pointer;
      }

      &:hover {
        cursor: pointer;
        background: $black-8;
        border-radius: 4px;
        border: none;
      }
    }

    &__spinner {
      margin-left: auto;
    }
  }

  &__body:has(&__body__spinner) {
    display: flex;
  }

  &__modal {
    padding: 24px;
    max-width: 400px;
    width: 100%;

    &__body {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    &__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    &__text {
      font-size: 14px;
      line-height: 16px;
    }

    &__buttons {
      display: flex;
      gap: 24px;
      justify-content: center;
      margin-top: 8px;

      button {
        flex: 1;
      }
    }

    &__cancel {
      border: 2px solid $black-16;
      color: $black-87;

      &:hover:enabled {
        background: $black-16;
      }
    }
  }

  &__grey-text {
    font-weight: 400 !important;
    color: $black-64;
  }
}

@media screen and (max-width: 768px) {
  .clients-container {
    &__header {
      align-items: center;
      gap: 16px;

      &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
      }

      &__search {
        padding-right: 42px;
      }
    }

    &__body {
      grid-template-columns: 3fr 1fr 1fr;

      &__header,
      &__card {
        grid-template-columns: 3fr 1fr 1fr;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      &__header {
        margin: 0 8px;
        border-bottom: 1px solid $black-16;
        padding-bottom: 4px;
        font-size: 12px;
        line-height: 16px;

        div {
          &:first-child {
            padding-left: 16px;
          }
        }

        &__date,
        &__city {
          align-self: flex-start;
        }

        &__city {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          text-align: right;
          margin-right: 8px;
        }

        &__controls {
          display: flex;
          gap: 24px;
          padding-right: 0 !important;
          margin-left: auto;

          button {
            &:last-child {
              color: $red-rufous-base;
            }
          }
        }
      }

      &__card {
        margin: 0 8px;
        font-size: 12px;
        line-height: 14px;

        span {
          font-size: 12px;
          line-height: 14px;
        }

        div {
          &:first-child {
            padding-left: 16px;
            gap: 4px;
          }
        }

        &__age {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 8px;
        }
      }
    }
  }
}
