@import '../../../styles/_variables';

.contact-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;

  &__switch {
    display: flex;
    align-items: center;
    gap: 16px;

    &__input {
      padding: 7px 16px;
      height: 32px;
      border: none;
      outline: 1px solid $black-16;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1em;
      color: $black-87;
      flex-grow: 1;
      transition: all ease 0.5s, outline 1ms;

      &:disabled {
        outline: 1px solid $black-8;
        background: $black-8;
      }

      &:focus {
        outline: 2px solid $extra-blue;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-container {
    &__switch .switch {
      order: 2;
    }
  }
}
