@import '../../styles/_variables';
@import '../../styles/_mixins';

.personal-cabinet-container {
  display: flex;
  flex-direction: column;
  padding: 40px 12px;
  width: 100%;
  max-width: 844px;
  max-height: 100vh;
  overflow-y: auto;
  margin: 0 auto;

  @include scrollbar(24px);

  &__controls-container {
    box-shadow: 0 -2px 0 0 $black-16 inset;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;

    &__tooltip {
      width: fit-content;
      transform: translate(0, 100%);
      margin-top: 28px;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__balance {
      display: flex;
      align-items: center;

      img {
        filter: invert(51%) sepia(21%) saturate(7267%) hue-rotate(98deg)
          brightness(96%) contrast(69%);
      }

      &-title,
      &-value {
        font-size: 18px;
        line-height: 21px;
      }

      &-value {
        font-weight: bold;
        margin-right: 12px;
      }
    }
  }

  &__body {
    height: fit-content;
  }
}

.event-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $clear-white;
  box-shadow: 0 4px 8px $black-16;
  border-radius: 8px;
  margin: 8px 16px 0 16px;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 12px 16px;
    max-height: 48px;
    border-radius: 8px;
    z-index: 3;
    background: $clear-white;

    button {
      border: none;
      background: transparent;
      height: 24px;
      width: 24px;
      cursor: pointer;
      transition: all 0.2s;

      &__active {
        transform: rotate(90deg);
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    color: $black-48;
  }

  &__date {
    font-size: 12px;
    line-height: 12px;
    color: $black-72;
  }

  &__button {
    &-active {
      transform: rotate(-180deg);
    }
  }

  &__status {
    margin-left: auto;
    font-size: 16px;
    line-height: 20px;
  }

  &__input {
    padding: 0 2px;
    border: none;
    height: fit-content;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    text-underline-offset: 2px;
    background: $clear-white;
    min-width: 0;
    width: 100%;

    &:focus {
      text-decoration: none;
      padding: 6px 12px;
      border: none;
      outline: 2px solid $extra-blue;
      border-radius: 8px;
    }
  }

  &__small-input {
    padding: 0 2px;
    border: none;
    height: fit-content;
    min-width: 0;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-decoration: underline;
    text-underline-offset: 2px;
    background: transparent;

    &:focus {
      text-decoration: none;
      padding: 4px 12px;
      border: none;
      outline: 2px solid $extra-blue;
      border-radius: 8px;
      background: $clear-white;
    }
  }

  &__delete-button,
  &__default-button {
    color: $red-rufous-base;
    margin-left: auto;
  }

  &__default-button {
    color: $black-48;
  }

  &__spinner {
    width: 24px;
    height: 24px;
    transform: scale(0.5);
  }
}

@media screen and (max-width: 768px) {
  .personal-cabinet-container {
    padding: 24px 0;
    height: calc(100vh - 48px);
    overflow-y: auto;

    &__header {
      padding: 0 24px 16px;

      &__title {
        font-size: 20px;
        line-height: 24px;
      }

      &__balance-title,
      &__balance-value {
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__controls-container {
      &__body {
        overflow-y: hidden;
        overflow-x: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }

      button {
        font-size: 12px;
        line-height: 14px;
        min-width: 120px;
      }
    }

    &__body {
      padding: 12px 6px 0 24px;
    }
  }

  .event-card-container {
    &__status {
      margin-left: auto;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
