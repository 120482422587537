@import '../../../styles/_variables';

.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  min-width: 0;
  width: 100%;
}

.input-title {
  font-size: 12px;
  line-height: 14px;
}

.input-title-error {
  color: $red-rufous-base;
}

.input {
  background: white;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  outline: 1px solid $black-16;

  &:focus {
    outline: 2px solid $extra-blue;
  }
}

.input-error {
  outline: 1px solid $red-rufous-base;

  &:focus {
    outline: 2px solid $red-rufous-base;
  }
}
