$blue-base: #2c59a5;
$blue-middle: #4e74b3;
$extra-blue: #2662c9;
$blue-gray: #d0d7e3;
$extra-blue-small-opacity: #d4deee;
$red-rufous-base: #a61911;
$extra-red: #d73434;
$yellow: #ffb545;
$melon-extra: #ffefcf;
$orange-base: #ffba33;
$green-pantone-base: #28a43d;
$green-celadon-extra: #cdf0d2;
$pink-extra: #f8d5d3;
$gray: #d9d9d9;
$gray-cultured: #f9f9f9;
$clear-white: #ffffff;
$black-4: rgba(0, 0, 0, 0.04);
$black-8: rgba(0, 0, 0, 0.08);
$black-16: rgba(0, 0, 0, 0.16);
$black-25: rgba(0, 0, 0, 0.25);
$black-32: rgba(0, 0, 0, 0.32);
$black-48: rgba(0, 0, 0, 0.48);
$black-64: #5c5c5c;
$black-72: #474747;
$black-87: #212121;
