@import '../../../styles/_variables';

.checkbox-container {
  width: fit-content;

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    width: 100%;
    margin-left: auto;

    &:hover {
      cursor: pointer;
    }
  }

  input {
    appearance: none;
    width: 18px;
    height: 18px;
    border: 2px solid $black-32;
    border-radius: 2px;

    &:hover {
      cursor: pointer;
    }

    &:checked {
      border: 2px solid $extra-blue;
    }
  }

  &__icon {
    position: absolute;
    font-size: 16px;
    margin-left: 4px;
    margin-top: 2px;
  }
}
