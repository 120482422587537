@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.analysis-panel {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px 24px;
  background: linear-gradient(93.29deg, $clear-white 14.86%, $gray 100%);
  border-top: 1px solid $black-32;

  @include scrollbar();

  &__opened {
    position: absolute;
    bottom: 0;
    height: fit-content;
  }

  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;

    &__opened {
      align-items: initial;
    }

    &__content {
      &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }

      &__specialist {
        display: flex;
        gap: 24px;
      }

      &__avatar {
        width: 90px;
        height: 90px;
        border-radius: 50%;
      }

      &__specialist-title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }

      &__specialist-jobs {
        font-size: 15px;
        line-height: 18px;
        color: $green-pantone-base;
        margin-top: 4px;
      }

      &__specialist-name {
        font-size: 18px;
        line-height: 21px;
        margin-top: 16px;
      }

      &__specialist-header {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__content {
    max-width: 820px;
    width: 100%;
    overflow: hidden;
  }

  &__open-button {
    border: none;
    background: transparent;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
    color: $black-64;
  }

  &__body {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 16px;
    margin-top: 32px;
    padding: 0 24px;

    &__left-button,
    &__right-button {
      position: absolute;
      background: transparent;
      border: none;
      cursor: pointer;
      top: 50%;

      &:disabled {
        color: $black-32;
      }
    }

    &__right-button {
      right: 0;
    }

    &__first-block {
      display: flex;
      flex-direction: column;

      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: $black-72;
        font-size: 14px;
        line-height: 16px;
        padding-left: 20px;
        padding-bottom: 32px;

        span {
          font-weight: 500;
        }
      }
    }

    &__second-block {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;

      &__info {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        color: $black-72;

        svg {
          width: 100%;
          max-width: 24px;
          max-height: 24px;
          color: $black-48;
        }
      }
    }

    &__specialist {
      &-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      &-profession {
        font-size: 15px;
        line-height: 18px;
        color: $green-pantone-base;
      }
    }

    &__form-card {
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 8px 24px;
      border: 1px solid $black-32;
      border-radius: 8px;
      margin-bottom: 10px;

      &__content {
        &__title {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__time {
        font-size: 14px;
        line-height: 16px;
        color: $black-64;
      }

      &__button {
        margin-left: auto;
      }

      svg {
        color: $black-32;
      }
    }

    &__info {
      display: flex;
      justify-content: flex-end;
      gap: 6px;
      margin-top: 22px;
      margin-bottom: 24px;

      span {
        max-width: 330px;
        font-size: 14px;
        line-height: 16px;
        color: $black-72;
      }

      &__image {
        width: 24px;
        height: 24px;
        color: $black-48;
      }
    }

    &__form-button {
      display: flex;
      justify-content: center;
      width: 100%;

      button {
        width: fit-content;
        background: $clear-white;
        padding: 8px 28px;
      }
    }

    &__nutritionist {
      position: absolute;
      bottom: -18px;
      left: 50%;
      transform: translate(-85%);
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1360px) {
  .analysis-panel {
    &__body {
      &__nutritionist {
        width: 100px;
        height: 158px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .analysis-panel {
    &__open-button {
      margin-right: 8px;

      &__opened {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-right: 0;
        margin-bottom: 18px;
      }

      span {
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 18px;
        color: $black-87;
      }
    }

    &__header {
      &__opened {
        flex-wrap: wrap;
      }

      &__content {
        &__opened {
          padding: 0 40px;
        }

        &__title {
          font-size: 18px;
          line-height: 21px;
          white-space: nowrap;
        }

        &__avatar {
          height: 48px;
          width: 48px;
          grid-area: 1 / 1 / 2 / 2;
        }

        &__specialist-header {
          grid-area: 1 / 2 / 2 / 7;
        }

        &__specialist-title {
          font-size: 18px;
          line-height: 21px;
        }

        &__specialist-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin-top: 0;
        }

        &__specialist-jobs {
          font-size: 14px;
          line-height: 16px;
          grid-area: 2 / 1 / 3 / 7;
        }

        &__specialist {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-column-gap: 8px;
          grid-row-gap: 8px;
        }
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      padding: 0;
      margin-top: 16px;

      &__right-button {
        top: 64px;
        right: 24px;
      }

      &__left-button {
        top: 64px;
        left: 24px;
      }

      &__first-block {
        ul {
          width: 100%;
          padding-bottom: 0;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 16px;
        }
      }

      &__second-block {
        font-size: 14px;
        line-height: 20px;
      }

      &__info {
        span {
          max-width: 100%;
        }
      }

      &__form-button {
        button {
          width: 100%;
        }
      }

      &__nutritionist {
        top: 125px;
        left: 315px;
        width: 100px;
        height: 158px;
      }
    }
  }
}

.analysis-panel__fade-in {
  animation: fade-in-anim 1.5s;
}

@keyframes fade-in-anim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
