@import '../../styles/_variables';

.bank-requisites {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 24px;
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    &__child {
      &:first-child {
        grid-area: 1 / 1 / 2 / 3;
      }
    }
  }

  &__save-button {
    max-width: 158px;
    height: 44px;
    margin-top: 16px;
  }
}
