@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.analysis-group-card {
  background: $clear-white;
  border-radius: 8px;
  box-shadow: 0 4px 4px $black-16;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 16px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 6px 10px $black-32;
  }

  &__chevron {
    height: 24px;
    color: $black-64;
  }

  &__group-count {
    font-size: 14px;
    line-height: 16px;
    color: $green-pantone-base;
    margin-left: auto;
    white-space: nowrap;
  }
}
