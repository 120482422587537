@import '../../styles/_variables';

.delivery-course-card {
  border-radius: 8px;
  margin-bottom: 8px;
  box-shadow: 0 4px 4px $black-16;
  padding-right: -4px;
  padding-left: -4px;
  margin-right: 4px;
  margin-left: 4px;

  &__header {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    padding: 0 24px 0 16px;

    &:hover {
      background: $black-8;
      cursor: pointer;
    }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 24px 0 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &__status {
      font-size: 14px;
      line-height: 24px;
      color: $black-72;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }

    &__price {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: right;
      margin-left: auto;
      white-space: nowrap;
    }

    &__opened {
      border-bottom: 1px solid $black-16;
      border-radius: 8px 8px 0 0;
    }

    &__spinner {
      background: transparent;
      height: 24px;
      width: 24px;

      div {
        &:first-child::after {
          width: 24px;
          height: 24px;
          border-width: 2px;
        }
      }
    }
  }

  &__body {
    position: relative;
    display: flex;
    gap: 40px;
    padding: 16px 24px;

    &__statuses {
      position: relative;
      display: grid;
      grid-template-columns: 1fr;

      &__item {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      &__icon {
        flex-shrink: 0;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $clear-white;
        border: 1px solid $black-48;

        &-active {
          border-radius: 50%;
          border: 2px solid $extra-blue;
        }
      }

      &__status {
        font-size: 14px;
        line-height: 24px;
        white-space: nowrap;
      }

      &__date {
        font-size: 12px;
        line-height: 12px;
        color: $black-72;
        margin-left: auto;
        white-space: nowrap;
      }

      &__dotted-line {
        position: absolute;
        margin-left: 4px;
        height: 50%;
        align-self: center;
        border-left: 2px dashed $black-32;
        z-index: -1;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 14px;
      line-height: 24px;

      &__address {
        font-weight: 500;
      }
    }
  }

  &__opened {
    border: 1px solid $black-32;
  }
}

@media screen and (max-width: 768px) {
  .delivery-course-card {
    &__body {
      flex-direction: column-reverse;
      gap: 12px;
      padding: 16px;
    }
  }
}
