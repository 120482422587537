@import '../../../styles/_variables';

.calendar-container {
  position: relative;
  margin-top: 2px;

  &__label {
    font-size: 12px;
    line-height: 14px;
  }

  &__date[type='date'] {
    font-family: 'Roboto', sans-serif;
  }

  &__date {
    padding: 12px 16px;
    height: 44px;
    width: 162px;
    border: none;
    outline: 1px solid $black-16;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: $black-87;

    &:focus {
      outline: 2px solid $extra-blue;
    }
  }

  &__button-container {
    position: absolute;
    top: 10px;
    right: 11px;
    width: 25px;
    height: 25px;
    background: #fff;
    pointer-events: none;

    button {
      border: none;
      background: transparent;
    }
  }
}
