@import '../../../styles/variables';

.text-container {
  font-size: 14px;

  &__count {
    color: $black-87;
    text-decoration: underline;
    text-underline-offset: 2px;
  }

  &__description {
    color: $black-72;
    text-decoration: underline;
    text-underline-offset: 2px;
  }
}
