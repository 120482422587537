@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.payments-container {
  padding-left: 0;
  padding-right: 0;

  &__header {
    gap: 22px;
    padding: 18px 0 24px 0;
  }
}

@media screen and (max-width: 768px) {
  .payments-container {
    &__header {
      padding-top: 6px;
    }
  }
}
