@import '../../../styles/variables';
@import '../../../styles/mixins';

.specialist-card {
  max-width: 820px;
  width: 100%;

  &__header {
    max-height: fit-content;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__photo {
    position: relative;
    width: 72px;
    height: 72px;
    margin-left: 8px;
    flex-shrink: 0;

    &__active {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
    }

    &__specialist {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid $black-16;

      &__empty {
        width: 100%;
        height: 100%;
        color: $black-16;
        padding: 16px;
      }
    }
  }

  &__name-container {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 20px;
    margin-left: 8px;
    margin-right: 16px;

    &__first-name {
      font-weight: 500;
    }
  }

  &__jobs-container {
    display: flex;
    flex: 1;
  }

  &__jobs {
    font-size: 14px;
    line-height: 20px;
    color: $black-72;
    width: fit-content;
    max-width: 225px;
    overflow-wrap: break-word;

    span {
      &:not(&:last-child) {
        &::after {
          content: ', ';
        }
      }
    }
  }

  &__city-info {
    display: flex;
    flex-direction: column;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    max-width: 135px;
    color: $black-72;
    margin-left: auto;

    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span {
      white-space: nowrap;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    &__description {
      white-space: pre-wrap;
    }

    &__attach {
      display: flex;
      align-items: center;
      gap: 8px;

      &__help-container {
        width: fit-content;
        transform: translate(50%, 0);
        margin-top: -8px;
      }

      &__help-icon {
        height: 24px;

        svg {
          color: $black-32;
        }
      }
    }

    &__view-profile {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      color: $black-72;

      &__links-container {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-left: 16px;
      }

      &__link-container {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__link {
        font-size: 14px;
        line-height: 18px;
        color: $black-87;
        text-decoration: 1px solid underline;
        text-underline-offset: 4px;
      }
    }
  }

  &__body-container {
    padding: 0;
  }

  &__current-specialist {
    outline: 2px solid $extra-blue;
  }
}

@media screen and (max-width: 769px) {
  .specialist-card {
    &__header {
      max-height: 96px;
      display: flex;
      gap: 8px;
    }

    &__photo {
      width: 44px;
      height: 44px;
      margin-left: 0;

      &__active {
        width: 12px;
        height: 12px;
      }
    }

    &__name-container {
      margin-right: 0;

      &__first-name {
        font-size: 14px;
        line-height: 16px;
      }

      &__last-name {
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__city-info {
      margin-left: auto;

      &__title {
        display: none;
      }

      &__experience {
        font-size: 11px;
        line-height: 16px;
        text-align: right;
        color: $black-72;
      }
    }

    &__work-skills {
      padding: 0 16px 12px;

      &__content {
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $black-16;
        padding-top: 8px;

        &__jobs {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: $black-72;

          span {
            &:not(&:last-child) {
              &::after {
                content: ', ';
              }
            }
          }
        }

        &__jobs-one-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__city {
          font-size: 12px;
          line-height: 16px;
          text-align: right;
          color: $black-72;
        }
      }
    }

    &__body {
      padding: 12px 16px 16px;
      gap: 16px;

      &__attach {
        button {
          font-size: 14px;
          line-height: 20px;
          width: 100%;
        }

        &__help-container {
          transform: translate(0, 0);
          margin-top: 0;
        }
      }

      &__description {
        font-size: 12px;
        line-height: 16px;
      }

      &__view-profile {
        &__title {
          font-size: 12px;
          line-height: 16px;
          color: $black-72;
        }

        &__link {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
