@import '../../styles/_variables';
@import '../../styles/_mixins';

.course-panel-container {
  height: 100vh;
  width: 100%;
  max-width: 280px;
  background: $gray-cultured;
  border-left: 1px solid $black-32;
  padding: 42px 0 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  cursor: pointer;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 0 24px;

    &__course-collapse-btn {
      cursor: pointer;
      color: $black-72;
      border: none;
      background-color: transparent;
      height: 24px;
    }

    img {
      cursor: pointer;
      transform: rotate(90deg);
    }

    &__title {
      color: $black-87;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      white-space: nowrap;
    }

    &__select {
      width: 255px;
      margin-left: auto;
    }

    &__collapse-btn {
      border: none;
      background: transparent;
      cursor: pointer;
      color: $extra-blue;
      font-size: 16px;
      line-height: 24px;
      text-decoration: underline dotted;
      text-underline-offset: 4px;
      white-space: nowrap;
      margin-left: auto;
      width: 125px;
    }
  }

  &__order-title {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 32px 0 16px 0;
    font-size: 20px;
    line-height: 24px;

    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 8px 8px 24px;
    margin-right: 16px;
    height: 100%;

    scrollbar-gutter: stable;
    overflow-y: auto;
    overflow-x: hidden;

    &__invitation {
      margin-top: 24px;
      font-size: 16px;
      line-height: 20px;
      color: $black-72;
    }

    &__analysis {
      margin: 0 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include scrollbar();
  }

  &__empty-text {
    margin-left: 64px;
    color: $black-72;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 24px;

    div {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__list-opened {
    display: flex;
    flex-direction: column;
    height: fit-content;
  }

  &__controls {
    box-shadow: 0 -2px 0 0 $black-16 inset;
  }
}

.course-panel-container-mobile {
  padding: 0;
  max-width: 48px;
  top: 0;
  right: 0;
  height: 100%;
  z-index: 4;

  &__header {
    white-space: nowrap;
  }
}

.open-panel {
  width: 100%;
  max-width: 727px;
  padding: 35px 0 0;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .course-panel-container {
    padding: 0;
    max-width: 48px;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 4;

    &:not(.open-panel) &__header {
      flex-direction: row-reverse;
      transform: rotate(-90deg);

      &__course-collapse-btn {
        transform: rotate(90deg);
      }

      &__title {
        font-size: 20px;
        line-height: 24px;
      }

      &__bio-count {
        white-space: nowrap;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;

        &:first-letter {
          font-weight: 700;
        }
      }
    }

    &__header {
      margin-top: 24px;
      margin-left: 8px;
      margin-right: 8px;

      &__course-collapse-btn {
        height: 24px;
      }

      &__select {
        max-width: 138px;
      }

      span {
        &:first-child {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    }

    &__order-title {
      margin: 26px 0 16px 0;
      font-size: 18px;
      line-height: 20px;
    }

    &__controls {
      margin-right: 0;
    }

    &__body {
      margin-right: 0;
      padding-left: 8px;
      padding-right: 8px;
    }

    &__list-opened {
      padding-right: 0;
    }
  }

  .open-panel {
    position: absolute;
    width: 100%;
    max-width: 100%;
    padding-top: 48px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1600px) {
  .open-panel {
    position: fixed;
    right: 0;
    height: 100vh;
    padding: 32px 0 0;
  }
}
