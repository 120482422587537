@import '../../../styles/_variables';

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switch__slider {
      background-color: $extra-blue-small-opacity;
    }

    &:focus + .switch__slider {
      box-shadow: none;
    }

    &:checked + .switch__slider:before {
      -ms-transform: translateX(14px);
      transform: translateX(14px);
      background-color: $extra-blue;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $black-8;
    transition: 0.4s;

    &__rounded {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }

    &:before {
      position: absolute;
      content: '';
      height: 14px;
      width: 14px;
      left: 4px;
      bottom: 3px;
      background-color: $extra-blue-small-opacity;
      transition: 0.4s;
    }
  }
}
