@import '../../styles/_variables';

.modal {
  position: fixed;
  background-color: $black-32;
  z-index: 2500;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  overflow: hidden;

  &__content {
    position: relative;
    background: $clear-white;
    margin: auto;
    box-shadow: 0 22.3363px 17.869px $black-4, 0 12.5216px 8px $black-8,
      0 6px 8px $black-4, 0 2px 2px $black-8;
    border-radius: 8px;
    height: fit-content;
  }

  &__close-button {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
    border: none;
    background: transparent;
    height: 24px;
    width: 24px;
  }
}

.full-screen-modal {
  background: $clear-white;
  padding: 0;

  &__content {
    height: 100%;
    width: 100%;
    box-shadow: none;
    border-right: 0;
  }
}

@media screen and (max-width: 768px) {
  .full-screen-modal {
    &__content {
      margin-top: 48px;
    }
  }
}
