@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.autocomplete-dropdown-container {
  position: absolute;
  margin-top: 65px;
  background: $clear-white;
  max-height: 240px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-gutter: stable;
  padding-right: 2px;
  box-shadow: 0 4px 4px $black-25;
  border-radius: 4px;
  z-index: 2;

  @include scrollbar();
}

.autocomplete-button {
  display: flex;
  background: $clear-white;
  border: none;
  cursor: pointer;
  height: 32px;
  padding: 0 12px;
  width: 100%;
  font-size: 16px;
  line-height: 32px;
  white-space: nowrap;

  &__bold-text {
    font-weight: bold;
  }

  &:hover {
    background: $black-8;
  }
}
