@mixin scrollbar($margin: null, $width: 8px, $border: null) {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: $width;
    border-radius: 90px;
    margin-left: 10px;
    background-clip: padding-box;
    box-shadow: inset 0 0 0 4px transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 90px;
    margin: $margin;
    background-color: $clear-white;
  }

  &::-webkit-scrollbar-thumb {
    border: $border;
    box-shadow: inset 0 0 0 4px $black-32;
    border-radius: 90px;
  }
}
