@import '../../../styles/_variables';

.filter-controls-container {
  display: flex;
  height: 48px;

  &__button {
    position: relative;
    cursor: pointer;
    height: 48px;
    width: 50%;
    max-width: 240px;
    margin-left: 0;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $black-72;
    white-space: nowrap;
    font-family: 'Roboto', sans-serif;

    &:hover {
      background: $black-4;
      color: $black-87;
    }

    &:disabled {
      background: $black-8;
      color: $black-32;
    }
  }
}

.active-filter {
  color: $black-87;

  span {
    font-weight: 500;
  }
}

.active-underline {
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid $extra-blue;
}
