@import '../../../styles/_variables';

.event-accrual-card {
  &__header {
    max-height: fit-content !important;
  }

  &__title-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    &__status {
      font-size: 14px;
      line-height: 16px;
      color: $black-64;
    }
  }

  &__price {
    display: flex;
    margin-left: auto;
    gap: 15px;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    color: $black-64;
  }

  &__profit {
    min-width: 120px;
    text-align: right;
    white-space: nowrap;
  }
}

@media screen and (max-width: 768px) {
  .event-accrual-card {
    &__body {
      &__status-container {
        display: flex;
        margin-left: 82px;
        gap: 12px;

        div {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }

    &__profit {
      margin-left: auto;
      min-width: 90px;
    }

    &__price {
      margin-left: 0;
      width: 100%;
      padding-right: 16px;

      div {
        &:nth-child(2) {
          margin-left: auto;
        }
      }
    }
  }
}
