@import '../../styles/_variables';

.time-card-item-container {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 0 16px;

  &:last-child {
    padding-bottom: 0;
  }

  &__image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  &__select {
    max-width: 145px;
    margin-left: auto;
  }
}

.time-card-container {
  &__title {
    font-weight: 500;
  }

  &__change-time {
    padding: 0 2px;
    border: none;
    height: fit-content;
    max-width: 76px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    text-underline-offset: 2px;
    background: $clear-white;

    &:focus {
      text-decoration: none;
      padding: 10px 12px;
      text-align: center;
      border: none;
      outline: 2px solid $extra-blue;
      border-radius: 8px;
      max-width: 64px;
    }
  }

  &__product-name {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &__product-count {
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
    text-overflow: ellipsis;
    text-align: right;
    margin-left: auto;
  }
}

@media screen and (max-width: 768px) {
  .time-card-container {
    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
