@import '../../../styles/_variables';

.event-card {
  &__body {
    width: 100%;
    background: $clear-white;
    border-radius: 0 0 8px 8px;
    z-index: 2;

    &__padding-block {
      padding: 8px 16px 16px;
    }
  }
}
