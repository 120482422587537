@import '../../styles/_variables';

.error-page {
  background: $clear-white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px;

  &__code-wrapper {
    display: flex;
    align-items: flex-end;
    gap: 62px;

    &__pills-icon,
    &__capsules-icon {
      margin-bottom: 44px;
    }

    &__pills-icon {
      width: 168px;
      height: 86px;
    }

    &__code {
      font-size: 200px;
      -webkit-text-stroke: 3px black;
      color: $clear-white;
    }

    &__capsules-icon {
      width: 144px;
      height: 70px;
    }
  }

  &__title {
    max-width: 450px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    color: $black-72;
  }
}

@media screen and (max-width: 768px) {
  .error-page {
    &__code-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 22px;

      &__pills-icon,
      &__capsules-icon {
        margin-bottom: 0;
      }

      &__pills-icon {
        width: 84px;
        height: 44px;
      }

      &__code {
        font-size: 82px;
        -webkit-text-stroke: 2px black;
      }

      &__capsules-icon {
        width: 82px;
        height: 40px;
      }
    }

    &__title {
      max-width: 334px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
