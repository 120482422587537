@import '../../../styles/_variables';

.bottom-container {
  background: $black-4;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin-top: auto;

  &__docs {
    button {
      transition: all 0.2s ease;
      padding-left: 68px;
      width: 100%;
    }
  }

  &__button,
  &__service-button {
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 16px;
    border: none;
    font-size: 14px;
    background: transparent;
    cursor: pointer;
    color: inherit;
    text-decoration: inherit;
    white-space: nowrap;
  }

  &__button {
    padding: 8px 0;
    justify-content: center;
  }

  &__button-opened {
    padding-left: 28px;
    justify-content: initial;
  }

  &__service-button {
    padding: 8px 32px;

    &:not(&__active):hover {
      background: $black-8;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;

      svg {
        margin-left: auto;
      }
    }

    &__active {
      background: $blue-base;
      color: $clear-white;
    }
  }

  &__profile-button {
    display: flex;
    gap: 8px;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 0 8px 32px;
    width: 100%;
    transition: all 0.2s ease;

    &:not(&__active):hover {
      background: $black-8;
    }

    &__active {
      background: $blue-base;
      color: $clear-white;
    }

    &__collapsed {
      justify-content: center;
    }

    &__short-name {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: $extra-blue-small-opacity;
      color: $extra-blue;
      border-radius: 50%;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      text-transform: uppercase;

      &__active {
        background: $blue-middle;
        color: $clear-white;
      }
    }

    &__full-name {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__dots {
    cursor: pointer;
    width: 100%;
    height: 24px;
    padding-left: 40px;
  }

  .bottom-container-minimized &__profile-button {
    padding-left: 0;
  }
}

.bottom-container-minimized {
  padding: 0;
}

.bottom-container-minimized .bottom-container__profile-button__active {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .bottom-container {
    margin-top: auto;

    &__button {
      padding-right: 12px;
    }

    &__profile-button {
      display: flex;
      justify-content: center;
      padding: 0;
      width: 48px;
      height: 100%;

      &__active {
        background: transparent;
      }

      &__short-name {
        &__active {
          background: $extra-blue;
        }
      }
    }
  }

  .bottom-container-minimized {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: auto;
  }

  .bottom-container-mobile .bottom-container__profile-button {
    justify-content: flex-start;
    width: 100%;
    padding: 4px 26px;
  }
}
