@import '../../../styles/_variables';

.event-survey-card {
  &__content {
    padding: 0 16px;
  }

  &__body {
    padding: 0;
  }
}
