@import '../../../styles/_variables';

.radio-button-label {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 18px;

  &__disabled {
    color: $gray;
  }
}

.radio-button {
  display: grid;
  place-content: center;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: $black-32;
  width: 20px;
  height: 20px;
  border: 2px solid $black-32;
  border-radius: 50%;
  transform: translateY(-0.075em);

  &::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    transform: scale(0);
    box-shadow: inset 1em 1em $extra-blue;
  }

  &:checked {
    border: 2px solid $extra-blue;

    &::before {
      transform: scale(1);
    }
  }
}
