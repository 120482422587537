@import '../../../styles/_variables';

.portal-tooltip-container {
  position: fixed;
  background: $black-72;
  color: $clear-white;
  font-size: 14px;
  line-height: 20px;
  border-radius: 2px;
  z-index: 100;
  width: 100%;
  max-width: 450px;

  &__text {
    padding: 6px 16px;
  }
}

@media screen and (max-width: 768px) {
  .portal-tooltip-container {
    max-width: 250px;
  }
}
