@import '../../styles/variables';
@import '../../styles/_mixins';

.delivery-container {
  &__map-controls {
    display: flex;
    margin-top: 22px;
    gap: 32px;
  }

  &__comment {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 10px;

    &__title {
      cursor: pointer;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 4px;
      font-size: 12px;
      line-height: 14px;
      color: $black-72;
    }

    &__text {
      resize: none;
      height: 100px;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid $black-16;
      border-radius: 8px;
      padding: 8px 16px;

      @include scrollbar();
    }
  }
}

@media screen and (max-width: 768px) {
  .delivery-container {
    &__map-controls {
      flex-direction: column;
      gap: 24px;
    }
  }
}
