@import '../../../styles/variables';

.facts-table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.facts-table {
  border-collapse: collapse;
  margin: 0 -20px;

  thead {
    border-bottom: 2px solid $black-16;

    tr td {
      padding: 8px 20px;
      color: $black-64;
      font-size: 14px;
      line-height: 16px;

      &:first-child {
        width: 100%;
      }

      &:nth-child(2),
      &:last-child {
        text-align: right;
        white-space: nowrap;
        padding-left: 20px;
      }
    }
  }

  tbody {
    tr:nth-child(even) td {
      background: $black-8;
    }

    tr td {
      padding: 8px 20px;

      &:nth-child(2),
      &:last-child {
        text-align: right;
        white-space: nowrap;
        padding-left: 20px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .facts-table-container {
    width: calc(100% + 48px);
    margin: 0 -24px;
    padding: 0 24px;

    thead {
      tr td {
        &:first-child {
          padding: 8px 0 8px 20px;
        }

        &:last-child {
          padding-left: 0;
        }
      }
    }

    tbody {
      tr td {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
