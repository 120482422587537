@import '../../../styles/_variables';

.dropdown-menu-container {
  &__header {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__control-button {
    border: none;
    background: transparent;
    cursor: pointer;
    margin-right: 16px;
    height: 24px;
  }
}
