@import '../../styles/_variables';

.total-pay-panel {
  padding: 6px 16px 6px 20px;

  &__info-container {
    &__block-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      column-gap: 80px;
    }

    &__block,
    &__header-block {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 16px;
      line-height: 24px;
      width: 100%;

      &-title {
        width: 100%;
      }

      &-price {
        white-space: nowrap;
      }

      &-button {
        position: relative;
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }

    &__header-block {
      width: 50%;
      padding-right: 40px;
    }

    &__image {
      color: $black-32;

      &:hover {
        color: $black-87;
      }
    }
  }

  &__table-container {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__text-right {
      text-align: right;
    }

    table {
      border-collapse: separate;
      border-spacing: 0;
      margin-bottom: 20px;

      thead {
        tr {
          color: $black-64;
          text-align: left;
          font-weight: 500;
          font-size: 12px;
          line-height: 24px;
        }

        td {
          border-bottom: 2px solid $black-16;
        }
      }

      tbody {
        tr {
          font-size: 14px;
          line-height: 32px;
          color: $black-87;
        }

        td {
          border-bottom: 1px solid $black-16;
        }
      }
    }
  }
}

.total-pay-main-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $black-87;
  width: 50%;
  white-space: nowrap;
}

.total-pay-second-text {
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  color: $black-72;
  margin-left: auto;
}

.total-pay-order-button {
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .total-pay-panel {
    padding: 6px 16px 6px 20px;

    &__info-container {
      &__header-block {
        width: 100%;
        padding: 18px 0 0 0;
      }

      &__block-title {
        padding-left: 40px;
      }

      &__block-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .total-pay {
    &__header {
      &__title {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .total-pay-main-text {
    font-size: 16px;
    line-height: 20px;
  }

  .total-pay-second-text {
    font-size: 12px;
    line-height: 14px;
    margin-left: 0;
  }
}
