@import '../../styles/_variables';
@import '../../styles/_mixins';

.modal-payment {
  transform: scale(0);

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px 40px;
    background: $clear-white;
    border-radius: 8px;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }

    &__description {
      font-size: 14px;
      line-height: 24px;
      color: $black-64;
    }

    &__link-to-account {
      cursor: pointer;
      background: transparent;
      border: none;
      outline: none;
      color: $extra-blue;
      text-decoration: underline;
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 22px;
    }

    &__requisites-first {
      display: flex;
      flex-direction: column;
      gap: 8px;

      span,
      &__requisites-second span {
        font-size: 14px;
        line-height: 20px;

        &:first-child {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &__requisites-second {
      display: flex;
      justify-content: space-between;
      gap: 8px;

      div,
      label {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__input {
        max-width: 130px;
      }
    }
  }

  &__close-btn {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border: none;
    color: $black-64;
    background-color: transparent;
  }
}

.modal-payment-active {
  transform: scale(1);
}

@media screen and (max-width: 768px) {
  .modal-payment {
    padding: 0;

    &__content {
      display: initial;
      width: 100%;
      height: 100%;
      border-radius: 0;
      padding: 0;

      &__description {
        &__short {
          padding: 0 24px 16px;
        }
      }

      &__link-to-account {
        &__short {
          padding: 0 24px 16px;
        }
      }

      &__title-container {
        border-bottom: 1px solid $black-16;
        padding: 18px 24px;
      }

      &__info {
        padding: 18px 24px;
      }

      &__requisites-first,
      &__requisites-second {
        padding: 0 24px 16px;
      }
    }

    &__create-button {
      margin: 0 24px;
      width: calc(100% - 48px);
    }
  }
}
