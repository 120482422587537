@import '../../styles/variables';

.payment-card-template {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  width: 355px;
  height: 225px;
  background: $black-4;
  border-radius: 16px;
  border: 1px solid $black-16;
  margin-top: 16px;
  padding: 24px;

  &__input {
    padding: 12px 16px;
    border: 1px solid $black-16;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
  }

  &__label {
    position: relative;

    &:first-child {
      grid-column: span 2;

      &::before,
      &::after {
        position: absolute;
        right: 26px;
        top: 34px;
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: $extra-red;
        border-radius: 50%;
        z-index: 2;
      }

      &::after {
        background: $yellow;
        right: 16px;
        z-index: 1;
      }
    }

    &:nth-child(2) {
      div {
        display: flex;
        align-items: center;
        font-size: 16px;
        gap: 8px;
      }

      input {
        width: 66px;
      }
    }

    &:nth-child(3) {
      margin-left: auto;

      input {
        width: 66px;
      }
    }

    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 12px;
    line-height: 14px;
  }

  &__remember-button {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      white-space: nowrap;
    }

    svg {
      color: $black-87;
    }
  }
}
