@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.profile-data-container {
  margin-top: 32px;
  width: 100%;
  padding: 0 2px;

  &__header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 18px;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
    }

    &__button {
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 16px;
      line-height: 20px;
      text-decoration-line: underline;
      color: $extra-blue;
    }

    &__info {
      color: $black-32;

      &-tooltip {
        margin-left: 200px;
      }
    }
  }

  &__form {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    column-gap: 24px;
    row-gap: 16px;

    &__errors {
      margin-top: 16px;
      padding: 14px 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black-87;
    }
  }

  &__course {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 32px;
      margin-left: 4px;
    }

    &__description {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 24px;
    }

    &__button {
      width: fit-content;
    }

    &__cards-container {
      overflow-y: auto;

      @include scrollbar();
    }
  }
}

@media screen and (max-width: 768px) {
  .profile-data-container {
    margin-top: 0;

    &__header {
      &__info-tooltip {
        margin-left: 0;
      }
    }

    &__form {
      grid-template-columns: 1fr 1fr;
      margin-bottom: 32px;

      label {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:last-child {
          grid-column: span 2;
        }
      }
    }

    &__course {
      &__title {
        margin-bottom: 16px;
      }

      &__cards-container {
        height: 100%;
      }
    }
  }
}
