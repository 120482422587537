@import '../../styles/_variables';

.shipping-payment-container {
  padding: 12px 20px;
}

.shipping-payment-main-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $black-87;
  white-space: nowrap;
}

.shipping-payment-second-text {
  margin-left: auto;
  white-space: nowrap;
  font-size: 14px;
  line-height: 24px;
  color: $black-72;
}

@media screen and (max-width: 768px) {
  .shipping-payment-second-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 4px;
    font-size: 12px;
    line-height: 14px;
  }

  .shipping-payment-main-text {
    font-size: 18px;
    line-height: 21px;
  }
}
