@import '../../../styles/_variables';

.event-payment-card-container {
  background: $clear-white;
  border-radius: 8px;
  box-shadow: 0 4px 4px $black-16;

  &__header {
    gap: 16px !important;
  }

  &__request {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__status {
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    color: $black-64;
    margin-left: 8px;
  }

  &__payment {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-left: auto;
    text-align: right;
  }

  &__body {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 22px 16px 22px;

    span {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .event-payment-card-container {
    &__request-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    &__request-container:has(&__status) {
      margin-top: 18px;
    }

    &__request {
      font-size: 14px;
      line-height: 16px;
    }

    &__payment {
      font-size: 14px;
      line-height: 16px;
    }

    &__status {
      font-size: 12px;
      line-height: 14px;
      margin-left: 0;
    }

    &__body {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      row-gap: 8px;
      column-gap: 8px;
      grid-auto-flow: column;
      padding: 12px 16px;
    }
  }
}
