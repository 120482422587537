@import '../../../styles/_variables';

.event-consultation {
  &__header {
    &__button-group {
      display: flex;
      gap: 16px;

      svg {
        color: $black-48;
      }

      button {
        &:last-child svg {
          color: $red-rufous-base;
        }
      }
    }
  }

  &__body {
    &__item {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &:not(&:last-child) {
        margin-bottom: 24px;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      label {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
