@import '../../../styles/_variables';

.event-course-container {
  &__active {
    outline: 2px solid $extra-blue;
  }

  &__controls {
    border-bottom: 2px solid $black-16;
  }

  &__time-card,
  &__supplement-card {
    border-radius: 0;
    margin-top: 0;
    box-shadow: none;
    border-bottom: 1px solid $black-16;

    &:last-child {
      border-radius: 0 0 8px 8px;
      border-bottom: none;
    }
  }

  &__body {
    padding: 0;
  }
}
