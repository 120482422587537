@import '../../styles/variables';
@import '../../styles/mixins';

.short-questions-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
}

.short-questions {
  &__wrapper {
    width: 100%;
    padding: 0 12px;
    margin: 6px 8px 6px 0;

    overflow-y: auto;
    @include scrollbar();
  }

  &__header {
    display: flex;
    align-items: center;
    height: 36px;
    max-width: 820px;
    margin: 30px auto 0 auto;

    &__back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 36px;
      height: 36px;
      padding: 0;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      margin-left: 26px;
    }

    &__answer-count {
      font-size: 16px;
      line-height: 19px;
      margin-left: 32px;
    }
  }

  &__content {
    max-width: 820px;
    margin: 26px auto 0 auto;

    &__question-container {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__question {
      width: fit-content;
    }

    &__question-opened {
      white-space: pre-wrap;
      text-overflow: initial;
      overflow: initial;
    }

    &__desc {
      font-size: 16px;
      line-height: 24px;
      color: $black-72;
    }

    &__questions {
      margin-top: 32px;

      &__item {
        display: grid;
        grid-template-columns: 9fr 3fr;
        align-content: center;
        padding: 0 12px;
        height: 72px;
        border-bottom: 1px solid $black-16;

        div {
          display: flex;
          align-items: center;
          gap: 16px;
          justify-content: flex-end;

          button {
            cursor: pointer;
            background: transparent;
            border: none;
            text-decoration: underline;
            text-underline-offset: 2px;
            height: 24px;
          }
        }

        span {
          margin: auto 0;
        }

        &__label {
          max-width: 76px;

          span {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 2px;
          }

          input {
            background: $clear-white;
            border: none;
            outline: 1px solid $black-16;
            border-radius: 8px;
            padding: 12px 16px;
            width: 76px;

            &:focus {
              outline: 2px solid $extra-blue;
            }
          }
        }

        &:hover:not(.short-questions__content__questions__item-answered) {
          background: $black-8;
          border-radius: 8px;
          border-bottom: 1px solid transparent;
        }
      }

      &__item-answered {
        display: flex;
        border: 1px solid $black-32;
        border-radius: 8px;
        margin: 8px 0;
        height: 48px;

        div {
          margin-left: auto;
        }

        span {
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
    }

    &__send-survey-container {
      margin-top: 12px;
    }
  }
}
