@import '../../styles/_variables';
@import '../../styles/_mixins';

section {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.list-container {
  width: 100%;
  max-width: 845px;
  height: 100vh;
  overflow: hidden;
  padding: 45px 12px;

  &__header__row:after,
  &__body__row:after {
    content: '';
    display: table;
    clear: both;
  }

  &__header__column,
  &__body__column {
    float: left;
  }

  &__header {
    &__row {
      border-bottom: 2px solid $black-16;
      padding-bottom: 6px;
      margin-right: 24px;
      font-size: 16px;
      line-height: 19px;
    }

    &__column {
      &:first-child {
        display: flex;
        align-items: center;
        gap: 7px;
        width: 300px;
      }

      &:nth-child(2) {
        width: 290px;
      }

      &:nth-child(3) {
        display: flex;
        align-items: center;
        gap: 4px;
        width: 122px;
      }
    }
  }

  &__body {
    height: 100%;
    overflow-y: auto;
    scrollbar-gutter: stable;
    padding-right: 16px;

    &__row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $black-16;
      padding: 8px 0;

      &:hover {
        border-radius: 8px;
        background: $black-8;
        border-bottom: 1px solid transparent;
      }
    }

    &__column {
      &:first-child {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 300px;
        font-size: 16px;
        line-height: 24px;
      }

      &:nth-child(2) {
        width: 290px;
        font-size: 14px;
        line-height: 18px;
        color: $black-64;
      }

      &:nth-child(3) {
        width: 100px;
        text-align: right;
        padding-right: 12px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      &:last-child {
        width: fit-content;
        margin-left: auto;
      }
    }

    @include scrollbar();
  }

  &__sort-button {
    background: transparent;
    border: none;
    cursor: pointer;

    img {
      filter: opacity(100%);
      opacity: 0.16;
    }
  }

  &__sort-button-active {
    img {
      transform: rotate(180deg);
      filter: invert(40%) sepia(15%) saturate(5636%) hue-rotate(195deg)
        brightness(81%) contrast(96%) opacity(100%);
      opacity: 1;
    }
  }

  &__sort-button-active-reverse {
    img {
      filter: invert(40%) sepia(15%) saturate(5636%) hue-rotate(195deg)
        brightness(81%) contrast(96%) opacity(100%);
      opacity: 1;
    }
  }
}

.error-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  color: darkred;
}

@media screen and (min-width: 769px) and (max-width: 1600px) {
  main:has(.open-panel) section {
    margin-right: 270px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
  .list-container {
    max-width: 700px;

    &__header {
      &__row {
        margin-right: 18px;
      }

      &__column {
        &:first-child {
          width: 206px;
        }

        &:nth-child(2) {
          width: 184px;
        }

        &:nth-child(3) {
          width: 120px;
        }
      }
    }

    &__body {
      padding-right: 10px;

      &__row {
        margin: 0;
      }

      &__column {
        &:first-child {
          width: 206px;
          font-size: 14px;
          line-height: 16px;
          flex-wrap: wrap;
        }

        &:nth-child(2) {
          width: 184px;
        }

        &:nth-child(3) {
          width: 100px;
          padding-right: 16px;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .list-container {
    width: 710px;

    &__body {
      padding-right: 4px;

      &__row {
        margin: 0;
      }

      &__column {
        &:first-child {
          gap: 8px;
        }
      }
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 869px) {
  .list-container {
    &__header {
      &__row {
        display: flex;
        flex-wrap: nowrap;
      }

      &__column {
        &:first-child {
          width: fit-content;
        }

        &:nth-child(2) {
          width: fit-content;
          margin-left: auto;
        }

        &:nth-child(3) {
          margin-left: auto;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .list-container {
    width: 550px;
    padding: 16px 8px 80px 8px;
    height: 100vh;
    z-index: 3;

    &__header {
      &__row {
        display: flex;
        justify-content: space-between;
        margin-right: 12px;
        padding-right: 12px;
        font-size: 14px;
        line-height: 16px;
      }

      &__column {
        &:nth-child(2) {
          display: flex;
          gap: 4px;
          text-align: right;
          justify-content: flex-end;
        }
      }
    }

    &__body {
      padding-right: 4px;

      &__row {
        margin: 0;
      }

      &__column {
        &:first-child {
          font-size: 14px;
          line-height: 16px;
        }
        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          margin-left: auto;
          font-size: 14px;
          line-height: 16px;
        }

        &:nth-child(4) {
          margin: 0;
        }
      }
    }
  }
}
