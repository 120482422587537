@import '../../styles/_variables';
@import '../../styles/_mixins';

nav {
  display: flex;
  flex-direction: column;
  border-right: 1px solid $black-32;
  max-width: 280px;
  height: 100vh;
}

.top-container {
  padding: 40px 35px 32px 40px;
  display: flex;
  align-items: center;
  gap: 50px;

  &__logo {
    cursor: pointer;
    border: none;
    background: none;
  }

  &__button {
    cursor: pointer;
    border: none;
    background: transparent;
  }

  &__tooltip {
    margin: -30px 0 0 50px;
  }

  &__tooltip-navbar {
    left: 80px;
  }
}

.controls {
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 16px;

  @include scrollbar();

  &__list {
    cursor: pointer;

    &__article-button {
      cursor: pointer;
      border: none;
      background: none;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      padding: 12px 40px;
      width: 100%;
      text-align: left;

      &__closed {
        padding: 8px 0;
        display: flex;
        justify-content: center;
      }

      &__icon {
        max-width: 24px;
        width: 24px;
      }

      &:not(.active-list-item):hover {
        background: $black-8;
      }
    }

    article {
      font-weight: 500;
      padding: 12px 40px;

      &:not(.active-list-item):hover {
        background: $black-8;
      }
    }

    &__list-item {
      list-style-type: none;
      padding: 10px 56px;
      line-height: 20px;
      white-space: nowrap;
      transition: all 0.2s ease;

      &:not(.active-list-item):hover {
        background: $black-8;
      }
    }

    &:last-child {
      article {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.controls-minimized {
  height: 100%;
  font-size: 16px;
  line-height: 16px;
  width: 76px;

  .controls__list {
    cursor: pointer;

    &__list-item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 0;
    }
  }
}

.top-container-minimized {
  padding: 44px 26px;
}

.active-list-item {
  background: $blue-base;
  color: $clear-white;

  img {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
      brightness(103%) contrast(103%);
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .nav-opened-tablet {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 32px 0 0;
    z-index: 10;
    background: $clear-white;
  }

  nav {
    max-width: 230px;
  }

  .top-container {
    padding: 16px 24px;
    gap: 0;

    &__button {
      margin-left: auto;
    }

    &__tooltip-navbar {
      left: 50px;
    }
  }

  .controls {
    font-size: 14px;

    &__list {
      &__article-button {
        font-size: 14px;
        padding: 10px 24px;
      }

      article {
        padding: 10px 24px;
      }

      &__list-item {
        padding: 10px 40px;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  .top-container-minimized {
    padding: 24px 12px;
  }

  .controls-minimized {
    width: 48px;
  }
}

@media screen and (max-width: 768px) {
  .nav-opened {
    position: absolute;
    height: 100vh;
    flex-direction: column;
  }

  nav {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background: $clear-white;
    flex-direction: row;
    height: 48px;
    width: 100%;
    max-width: 100%;
    box-shadow: 0 4px 4px $black-16;
    z-index: 5;
  }

  .mobile-close-button {
    border: none;
    background: transparent;
    height: 24px;
    color: $black-87;
    margin: 12px 20px 12px auto;
  }

  .top-container {
    display: none;
  }

  .controls-mobile {
    background: $clear-white;
    top: 48px;
    width: 100%;
    z-index: 3;
    overflow-y: auto;
  }

  .controls-mobile-minimized {
    display: none;
  }

  .top-container-minimized {
    display: flex;
    flex-direction: row-reverse;
    gap: 24px;
    padding: 12px 24px;
    align-items: center;
  }
}
