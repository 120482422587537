@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.analysis-modal-info {
  padding: 48px 16px 48px 48px;
  max-width: 960px;
  width: 100%;
  cursor: default;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__short-info {
      display: flex;
      align-items: center;

      &__wrapper {
        display: flex;
        align-items: center;
      }
    }

    &__price {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      margin-right: 8px;
    }

    &__reserved-price {
      padding: 2px 8px;
      font-size: 14px;
      line-height: 20px;
      color: $extra-blue;
      background: rgba(38, 98, 201, 0.16);
      border-radius: 4px;
      margin-right: 32px;
    }

    &__type-text {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      color: $black-72;
      margin-right: 24px;

      img {
        filter: invert(26%) sepia(99%) saturate(1426%) hue-rotate(204deg)
          brightness(100%) contrast(88%);
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
    padding-right: 12px;

    max-height: 384px;
    overflow: auto;
    @include scrollbar();

    &__description {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .analysis-modal-info {
    padding: 24px 16px 24px;
    margin-top: 0;

    &__header {
      &__short-info {
        flex-direction: column;
        align-items: initial;
        gap: 18px;
      }
    }

    &__body {
      max-height: 100%;
      padding-bottom: 12px;
    }
  }
}
