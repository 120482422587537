@import '../../../styles/_variables';

.card-container {
  display: flex;
  flex-direction: column;
  background-color: $clear-white;
  box-shadow: 0 4px 8px $black-16;
  margin-top: 8px;
  border-radius: 8px;

  &:first-child {
    margin-top: 16px;
  }

  &__dropdown-control-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 14px;
    border: none;
    background: transparent;
    margin-left: auto;
    font-size: 14px;
    line-height: 14px;
  }
}

.card-header {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 12px 20px;
  max-height: 48px;

  &__button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__image-container {
    display: flex;
    align-items: center;
    margin-right: 8px;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
  }

  &__title {
    font-weight: 500;
    text-decoration: underline;
    text-underline-offset: 4px;
  }

  &__delete-button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: $red-rufous-base;
  }
}

.card-body {
  padding: 12px 20px 12px;

  &__time-filter {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    padding: 16px 20px 0 20px;
    margin: 0 -20px 24px;
    border-top: 1px solid $black-16;

    &__button {
      width: fit-content;

      label {
        cursor: pointer;
        width: fit-content;
      }

      label span {
        text-align: center;
        display: block;
        padding: 6px 12px;
        outline: 1px solid $black-64;
        border-radius: 8px;
      }

      input:checked + span {
        outline: 2px solid $extra-blue;
      }
    }
  }
}

.bottom-divider {
  border: none;
  background-color: $black-16;
  height: 1px;
}

@media screen and (max-width: 768px) {
  .card-header {
    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
      padding-bottom: 1px;
    }
  }

  .card-body {
    overflow: hidden;
  }
}
