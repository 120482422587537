@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.tags-container {
  position: absolute;
  max-width: 210px;
  width: 210px;
  max-height: 280px;
  background: $clear-white;
  box-shadow: 0 4px 8px $black-25;
  border-radius: 4px;
  padding: 8px 8px 8px 16px;
  z-index: 1;
  overflow-y: auto;
  $scroll-margin: 4px 0;
  $scroll-width: 12px;
  $scroll-border: 4px solid $clear-white;

  @include scrollbar($scroll-margin, $scroll-width, $scroll-border);

  button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__control-button {
      white-space: nowrap;
      color: $extra-blue;
      text-decoration: underline;
      font-size: 14px;
      line-height: 24px;
    }

    &__add-button {
      height: 24px;
      color: $extra-blue;
    }
  }

  &__new-tag {
    display: flex;
    margin-top: 8px;

    &__input {
      border: none;
      outline: 1px solid $black-16;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;
      height: 32px;
      min-width: 0;
      width: 100%;
      margin-right: 16px;
      padding: 12px 16px;

      &:focus {
        outline: 2px solid $extra-blue;
      }
    }

    &__trash-button {
      color: $red-rufous-base;

      &:disabled {
        color: $black-32;
      }
    }

    &__tag-item {
      display: flex;
      align-items: center;
      margin-top: 8px;

      &__input {
        font-weight: 400 !important;
        font-size: 14px !important;
        margin-right: 16px;
        height: 32px !important;
      }

      &__button {
        width: 100%;
        text-align: left;
      }
    }
  }
}
