@import '../../../styles/_variables';

.event-file {
  &__body {
    padding: 8px 0;
  }

  &__body-item {
    display: flex;
    align-items: center;
    padding: 8px 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    &__button-group {
      display: flex;
      gap: 20px;
      margin-left: auto;

      &__download {
        color: $black-48 !important;
      }

      button {
        cursor: pointer;
        background: transparent;
        border: none;

        &:first-child {
          color: $black-48;
        }

        &:last-child {
          color: $red-rufous-base;
        }
      }

      &__link {
        svg {
          color: $black-48;
        }

        &:hover {
          svg {
            color: $black-64;
          }
        }
      }
    }

    &__name {
      border: none;
      background: transparent;
      text-decoration: underline;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 16px;
    }

    &__file-extension {
      padding-right: 6px;
      font-size: 14px;
      line-height: 16px;
    }

    &__spinner {
      &:after {
        width: 16px;
        height: 16px;
        margin: 0;
        border-width: 3px;
      }
    }
  }
}
