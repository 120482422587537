@import '../../styles/variables';
@import '../../styles/mixins';

.specialists-page {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    @include scrollbar(12px);
  }

  &__title {
    max-width: 820px;
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-left: 12px;
    margin-top: 40px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 8px;
    height: 100%;
    max-width: 820px;
    width: 100%;
    padding: 0 12px 12px;

    @include scrollbar(12px);
  }
}

@media screen and (max-width: 769px) {
  .specialists-page {
    &__list {
      margin-top: 0;
    }
  }
}
