@import '../../styles/_variables';

.modal {
  position: fixed;
  background-color: $black-32;
  z-index: 2500;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background: $clear-white;
    margin: auto;
    box-shadow: 0 22.3363px 17.869px $black-4, 0 12.5216px 8px $black-8,
      0 6px 8px $black-4, 0 2px 2px $black-8;
    border-radius: 8px;
    height: fit-content;

    &__header {
      display: flex;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid $black-16;
      padding: 8px 16px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      &__name {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }

      &__product-count {
        margin-left: auto;
        text-decoration: none !important;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 320px;
      }

      &__close-btn {
        cursor: pointer;
        border: none;
        background: transparent;
        height: 24px;
      }
    }

    &__controls-first {
      position: relative;
      display: flex;
      gap: 16px;

      &__freq-select {
        margin-left: auto;

        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }
      }

      &__label {
        display: flex;
        flex-direction: column;
        gap: 2px;
        width: fit-content;
        font-size: 12px;
        line-height: 14px;

        &:first-child {
          width: 204px;
        }
      }
    }

    &__controls-second {
      display: flex;
      flex-direction: column;

      &__input {
        background: #ffffff;
        border: 1px solid $black-16;
        border-radius: 8px;
        padding: 12px 16px;
        height: 44px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        max-width: 76px;

        &:focus {
          border: none;
          outline: 2px solid $extra-blue;
        }

        &:disabled {
          border: none;
          padding: 0;
          color: $black-87;
        }
      }

      &__del-button {
        cursor: pointer;
        border: none;
        background: transparent;
        height: 24px;
        margin-top: auto;
        margin-bottom: 10px;

        &:enabled {
          svg {
            color: $red-rufous-base;
          }
        }

        &:disabled {
          svg {
            color: $black-16;
          }
        }
      }

      &__child {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-bottom: 16px;

        &__label {
          display: flex;
          flex-direction: column;

          span {
            margin: 0 0 2px;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }

    &__body {
      padding: 16px 16px 24px;
      display: flex;
      flex-direction: column;
    }

    &__button {
      margin-left: auto;
      margin-right: 40px;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .modal {
    display: block;
    background-color: $clear-white;

    &-content {
      box-shadow: none;
      border-radius: 0;
      width: 100%;
      height: 100%;

      &__header {
        gap: 8px;
        padding: 24px;

        &__name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;
        }

        &__product-count {
          overflow: initial;
        }
      }

      &__controls-first {
        width: 100%;

        &__freq-select {
          max-width: 126px;
          width: 100%;
        }

        &__label {
          &:first-child {
            width: 100%;
          }
        }
      }

      &__controls-second {
        margin-top: 16px;
        margin-left: 0;
        width: 100%;

        &__titles {
          &:last-child {
            width: 100%;
          }
        }

        &__child {
          width: 100%;

          &__label {
            &:nth-child(2) {
              width: 100%;
            }
          }
        }
      }

      &__body {
        padding: 24px;
      }

      &__button {
        margin-right: 0;
      }
    }
  }
}
