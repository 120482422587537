@import '../../../styles/_variables';

.event-supplement {
  &__supp-filter,
  &__time-filter {
    box-shadow: none;
    margin-top: 0 !important;
  }

  &__link {
    color: $extra-blue;
    text-decoration: 1px solid underline;
    text-underline-offset: 4px;
    margin-left: auto;
  }

  &__link-button {
    color: $black-48;
  }
}
