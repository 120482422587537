@import '../../../styles/_variables';

.text-area {
  border: none;
  outline: 1px solid $black-16;
  border-radius: 8px;
  width: 100%;
  resize: vertical;
  padding: 12px 16px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 20px;
  overflow: auto;

  &:focus {
    outline: 2px solid $extra-blue;
  }
}

.text-area-container {
  position: relative;

  &__counter {
    position: absolute;
    right: 16px;
    bottom: 8px;
    color: $black-32;

    &__default {
      color: $black-87;
    }

    &__active {
      color: $extra-blue;
    }
  }
}
