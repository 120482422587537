@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.info-side-bar {
  position: relative;
  max-height: 100vh;
  width: 720px;
  background: $gray-cultured;
  border-left: 1px solid $black-32;
  padding: 42px 0 12px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 2;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 32px;
    gap: 8px;

    &__title {
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      white-space: nowrap;
    }

    &__button {
      cursor: pointer;
      border: none;
      background: transparent;
      font-size: 16px;
      line-height: 24px;
      color: $extra-blue;
      border-bottom: 1px dotted $extra-blue;
    }
  }

  &__close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    border: none;
    background: transparent;
  }

  &__list {
    margin: -24px 24px 0 8px;
    padding-bottom: 12px;
    padding-left: 8px;
    scrollbar-gutter: stable;
    overflow-y: auto;
    overflow-x: hidden;

    @include scrollbar();

    &__item-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-size: 16px;
      line-height: 20px;

      &:not(&:last-child) {
        margin-bottom: 16px;
      }

      &__time {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &__bold-time {
        font-weight: 500;
      }
    }
  }

  &__controls {
    margin: 0 32px 0 16px;
    box-shadow: 0 -2px 0 0 $black-16 inset;
  }

  &__clients-controls {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 0 32px 0 16px;

    svg {
      color: $black-48;

      &:hover {
        cursor: pointer;
        color: $extra-blue;
      }
    }

    img {
      filter: invert(55%) sepia(6%) saturate(17%) hue-rotate(315deg)
        brightness(95%) contrast(87%);

      &:hover {
        cursor: pointer;
        filter: invert(29%) sepia(77%) saturate(1364%) hue-rotate(199deg)
          brightness(94%) contrast(91%);
      }
    }

    &__tooltip {
      width: fit-content;
    }

    &__label {
      position: relative;
      width: 100%;

      svg {
        position: absolute;
        color: $black-32;
        right: 16px;
        top: 10px;
      }
    }

    &__input {
      padding: 10px 16px;
      width: 100%;
      height: 44px;
      border: none;
      outline: 2px solid $black-32;
      border-radius: 8px;
      font-size: 16px;
      line-height: 20px;

      &-icon {
        position: absolute;
        right: 16px;
        top: 10px;
      }

      &:focus {
        outline: 2px solid $extra-blue;
      }

      &:focus + .info-side-bar__clients-controls__comment-button img {
        filter: none !important;
      }
    }

    &__comment-button {
      position: absolute;
      right: 16px;
      top: 10px;
      border: none;
      background: transparent;
    }

    &__button,
    &__research,
    &__course {
      border: none;
      background: transparent;
      height: 18px;
      width: 18px;

      svg {
        width: 18px;
        height: 18px;
        color: $black-48;
      }
    }

    &__research,
    &__course {
      display: flex;
      align-items: center;
      width: 20px;
      height: 20px;

      img {
        width: 20px;
        height: 20px;
      }
    }

    &__events {
      overflow-y: auto;
      margin-right: 6px;
      padding-bottom: 10px;

      @include scrollbar();
    }
  }

  &__nutritionist {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    right: 24px;
    top: 28px;
    height: 50px;
    max-width: 320px;
    width: 100%;
    background: $black-4;
    border-radius: 25px;
    border: none;
    cursor: pointer;
    font-family: 'Roboto';
    margin-left: auto;

    &__icon-container {
      position: relative;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $blue-gray;
      border-radius: 50%;
      flex-shrink: 0;

      &__photo {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid $black-16;

        &__empty {
          width: 100%;
          height: 100%;
          color: $black-16;
          padding: 16px;
        }
      }

      &__status {
        width: 12px !important;
        height: 12px !important;
      }

      svg {
        color: $extra-blue;
        width: 16px;
        height: 16px;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__name-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }

    &__first-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    &__second-name {
      font-size: 12px;
      line-height: 14px;
    }

    &__tooltip {
      transform: translate(0, 100%);
      margin-top: 32px;

      &__icon {
        color: $black-32;
        height: 24px;
      }
    }
  }

  &__minimized {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 8px;
    margin-left: 6px;
    max-width: 40px;
    width: 100%;
    gap: 16px;
    border-left: 1px solid $black-32;
    cursor: pointer;

    &__button {
      border: none;
      background: transparent;
      cursor: pointer;
    }

    &__title {
      writing-mode: vertical-rl;
      text-orientation: mixed;
      transform: rotate(180deg);
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .info-side-bar {
    position: absolute;
    right: 0;
    width: 100%;
    max-width: 530px;
    height: 100%;

    &__header {
      margin-left: 12px;
    }

    &__list {
      margin-top: -24px;
    }
  }
}

@media screen and (max-width: 768px) {
  .info-side-bar {
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: calc(100% - 48px);
    padding-top: 0;
    gap: 0;

    &__header {
      padding: 18px 24px;
      margin: 0;
      justify-content: flex-start;
      gap: 16px;

      &__title {
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__clients-controls {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    &__controls {
      margin: 0 0 8px 0;
    }

    &__list {
      margin: -24px 0 16px 8px;
    }

    &__nutritionist {
      padding: 0;
      justify-content: center;
      height: 40px;
      max-width: 56px;
      width: 100%;

      &__title {
        display: none;
      }

      &__tooltip {
        &__icon {
          display: none;
        }
      }
    }

    &__close-button {
      order: -1;
      margin-left: 0;
    }
  }
}
