@import '../../styles/_variables';
@import '../../styles/_mixins';

.analysis-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 40px;
    margin: auto;
    width: 100%;
    max-width: 820px;

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }

    &__help {
      color: $black-48;
      height: 24px;
    }

    &__help-tooltip {
      margin-left: -22px;
      margin-top: 28px;
      transform: translate(100%, 100%);
    }

    &__search-container-mobile {
      margin-left: auto;
      height: 24px;

      &__control {
        border: none;
        background: transparent;
        height: 24px;
        margin-left: 24px;
        cursor: pointer;

        svg {
          color: $extra-blue;
        }
      }
    }

    &__search-container-modal {
      margin-top: 0;

      &__content {
        display: flex;
        flex-direction: column;
        padding: 28px 24px;
      }

      &__title {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }

      &__input {
        padding: 12px 16px;
        height: 44px;
        border: none;
        outline: 1px solid $black-16;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.1em;
        color: $black-87;
        margin-top: 22px;
        margin-bottom: 16px;

        &:focus {
          outline: 2px solid $extra-blue;
        }
      }

      &__found-elements {
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        max-height: calc(100vh - 156px);
        margin: 0 -14px 0 -6px;
        padding: 12px 6px;

        @include scrollbar();
      }
    }
  }

  &__filter {
    box-shadow: 0 -2px 0 0 $black-16 inset;
  }

  &__filter {
    margin: 16px auto 0 auto;
    width: 100%;
    max-width: 820px;
  }

  &__search-container {
    display: flex;
    align-items: center;
    gap: 24px;
    margin: 24px auto 16px auto;
    padding-left: 4px;
    max-width: 820px;
    width: 100%;

    &__location {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__marker-icon {
      color: $extra-blue;
    }

    &__city {
      position: relative;
      font-size: 16px;
      line-height: 20px;

      &:hover {
        cursor: pointer;
      }
    }

    &__city-arrow {
      width: 0;
      margin-top: 4.5px;
      border: 4.5px solid transparent;
      border-top-color: black;
    }

    &__back-button {
      background: $clear-white;
      max-width: 36px;
      width: 100%;
      height: 36px;
      border-radius: 50%;
      border: 2px solid $extra-blue;
      color: $extra-blue;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 36px;
      }

      &:hover {
        background: $extra-blue;
        color: $clear-white;
      }
    }

    &__current-group {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    &__no-margin {
      margin: 0;
    }
  }

  &__search {
    margin-right: 16px;

    input {
      padding-right: 40px;
    }
  }

  &__cards-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto;
    height: 100%;
    padding: 10px 12px 10px;
    margin: 0 auto;
    max-width: 830px;
    width: 100%;

    @include scrollbar(8px);
  }

  &__panel {
    &__header {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      &__title {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }

      &__tooltip {
        margin-top: 28px;
        transform: translate(50%, 100%);
      }

      &__order-form {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        border: none;
        background: transparent;
        margin-left: auto;

        svg {
          color: $black-48;
        }
      }

      &__count {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &__empty-order {
    font-size: 16px;
    line-height: 20px;
    color: $black-72;
  }

  &__ordering {
    margin-top: 30px;

    &__container {
      margin-top: 0 !important;
      padding: 16px;
    }

    &__header {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
    }

    &__title-image {
      filter: opacity(64%);
    }

    &__lab-address {
      padding: 12px 16px;
      margin-top: 16px;

      &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
      }

      &__checkbox {
        margin-top: 16px;
      }
    }

    &__total-price {
      padding: 6px 16px;
      margin-top: 8px;

      &__analysis-count {
        font-size: 14px;
        line-height: 24px;
        color: $black-72;
        margin-left: auto;
      }

      &__header {
        display: grid;
        grid-template-columns: 3fr 2fr 3fr 4fr;
        align-items: center;

        span {
          &:nth-child(2) {
            display: flex;
            align-items: center;
            gap: 8px;
          }

          &:nth-child(odd) {
            font-size: 16px;
            line-height: 24px;
            color: $black-72;
          }
        }
      }

      &__table {
        td {
          white-space: nowrap;

          &:first-child {
            width: 100%;
            max-width: 400px;
            overflow-x: hidden;
            text-overflow: ellipsis;
          }

          &:nth-child(2) {
            padding: 0 16px;
          }

          &:nth-child(3) {
            text-align: right;
          }
        }
      }
    }

    &__detail {
      display: grid;
      grid-template-columns: 3fr 9fr;
      margin-top: 24px;
      row-gap: 16px;
      grid-gap: 16px;

      &__bold-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }

    &__warning {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      border-left: 4px solid $orange-base;
      background: $melon-extra;
      margin: 8px 0;

      &__title,
      &__desc {
        font-size: 14px;
        line-height: 16px;
      }

      &__title {
        font-weight: 500;
      }
    }

    &__status-panel {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      padding: 16px;

      svg {
        max-width: 24px;
        width: 100%;
        color: $black-48;
      }

      &__button {
        white-space: nowrap;
      }

      &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 16px;
      }
    }

    &__total-price-container {
      display: flex;
      align-items: center;
      width: 100%;

      span {
        &:last-child {
          margin-left: 120px;
        }
      }
    }
  }
}

.analysis-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 16px 6px 0 0;
  overflow-y: auto;

  @include scrollbar();

  &__content {
    display: flex;
    flex-direction: column;
    max-width: 820px;
    width: 100%;

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 32px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      color: $black-72;
      margin-bottom: 32px;
    }

    &__file-title {
      margin-left: 16px;
    }

    &__table {
      display: flex;
      flex-direction: column;
      text-align: left;

      &-item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-content: center;
        height: 26px;
        padding: 0 16px;

        &:first-child {
          margin-bottom: 4px;
        }

        &:nth-child(2n + 3) {
          background: rgba(0, 0, 0, 0.08);
        }

        input {
          min-width: 0;
          width: 100%;
        }
      }

      &-title {
        font-size: 14px;
        line-height: 16px;
        color: $black-72;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-text {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__error {
    margin-top: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    a,
    span {
      color: $black-87;
    }
  }

  &__file-error {
    margin-top: 16px;
    font-size: 14px;
    line-height: 16px;
    color: $black-87;

    &__bold-text {
      font-weight: 500;
    }

    &__list {
      padding: 16px;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .analysis-container {
    &__content {
      &__table {
        tbody td {
          width: auto;
          padding: 0;

          input {
            width: fit-content;
          }
        }

        td {
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .analysis-page {
    justify-content: initial;
    padding: 0;
    height: calc(100vh - 48px);
    overflow-y: auto;

    &__header {
      padding: 24px 24px 16px;
    }

    &__filter {
      margin: 0;

      button {
        max-width: 100%;
        width: 50%;
        font-size: 12px;
        line-height: 14px;
      }
    }

    &__filter-controls {
      max-width: 100%;

      button {
        max-width: 100%;
        width: 50%;
      }
    }

    &__search-container {
      padding: 0 16px;
    }

    &__cards-wrapper {
      margin-top: 16px;
      padding: 0 10px 12px 16px;
      width: 100%;
      overflow-x: hidden;
    }

    &__empty-order {
      padding: 16px 0 0;
    }

    &__ordering {
      &__total-price-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        span {
          &:first-child {
            font-size: 16px;
            line-height: 20px;
          }

          &:last-child {
            font-size: 12px;
            line-height: 14px;
            margin-left: 0;
          }
        }
      }

      &__total-price {
        &__header {
          display: flex;
          flex-wrap: wrap;

          span {
            flex: 50%;
            margin-top: 8px;

            &:nth-child(odd) {
              color: $black-87;
            }

            &:nth-child(even) {
              justify-content: flex-end;
              text-align: right;
            }
          }
        }

        &__table {
          table {
            margin-bottom: 0;
          }

          td {
            &:first-child {
              width: 100%;
              max-width: 100px;
            }
          }
        }
      }

      &__status-panel {
        flex-direction: column;
        margin-top: 0;

        &__wrapper {
          gap: 8px;
        }

        button {
          width: 100%;
        }
      }
    }

    &__panel {
      &__header {
        &__tooltip {
          transform: translate(25%, 100%);
        }
      }
    }
  }

  .analysis-container {
    padding-right: 0;

    &__content {
      padding: 0 12px;
    }
  }
}
