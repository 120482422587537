@import '../../../styles/_variables';

.tooltip-container {
  position: absolute;
  background: $black-72;
  color: $clear-white;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 16px;
  border-radius: 2px;
  z-index: 100;
}
