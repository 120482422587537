@import '../../../styles/_variables';

.select-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &__chevron {
    position: absolute;
    pointer-events: none;
    right: 12px;
    color: $black-87;
  }
}

.select {
  padding: 8px 32px 8px 16px;
  height: 44px;
  border: 1px solid $black-16;
  border-radius: 8px;
  font-size: 16px;
  line-height: 20px;
  appearance: none;
  width: 100%;

  &:disabled {
    border: none;
    padding: 0 0 0 16px;
    color: $black-87;
    opacity: 1;
  }
}

.select-text-right select {
  text-align: right;
}
