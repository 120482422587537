@import '../../styles/_variables';

.modal-register {
  width: 480px;

  &__location {
    &__title {
      margin-left: 36px;
    }

    &__input {
      &:focus {
        border: none;
        outline: 2px solid $extra-blue;
      }
    }

    &__buttons-block {
      position: relative;
      margin-top: 4px;
    }
  }

  &__title {
    margin-bottom: 24px;
  }

  &__form-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    &__label {
      position: relative;
      display: flex;
      flex-direction: column;

      &-text {
        font-size: 12px;
        line-height: 14px;
      }

      &-input {
        font-size: 16px;
        line-height: 20px;
        padding: 12px 16px;
        border: 1px solid $black-16;
        border-radius: 8px;
        width: 100%;

        &:focus {
          border: none;
          outline: 2px solid $extra-blue;
        }
      }

      &:first-child {
        grid-area: 1 / 1 / 2 / 7;
      }
      &:nth-child(2) {
        grid-area: 2 / 1 / 3 / 4;
      }
      &:nth-child(3) {
        grid-area: 2 / 4 / 3 / 7;
      }
      &:nth-child(4) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(5) {
        grid-area: 3 / 3 / 4 / 7;
      }
      &:nth-child(6) {
        grid-area: 4 / 1 / 5 / 7;
      }
      &:last-child {
        grid-area: 5 / 1 / 6 / 7;
        width: 100%;
      }

      &:nth-child(n) {
        height: fit-content;
      }
    }

    &__privacy {
      font-size: 14px;
      line-height: 18px;
    }

    &__label-input-error {
      span {
        color: $red-rufous-base;
      }

      input {
        border: none;
        outline: 2px solid $red-rufous-base;

        &:focus {
          outline: 2px solid $red-rufous-base;
        }
      }
    }
  }

  &__form-container-cabinet {
    &__label {
      &__email {
        grid-area: 3 / 1 / 5 / 7 !important;
      }

      &__location {
        grid-area: 5 / 1 / 6 / 7 !important;
      }

      &:first-child {
        grid-area: 1 / 1 / 2 / 4;
      }
      &:nth-child(2) {
        grid-area: 1 / 4 / 2 / 7;
      }
      &:nth-child(3) {
        grid-area: 2 / 1 / 3 / 4;
      }
      &:nth-child(4) {
        grid-area: 2 / 4 / 3 / 7;
      }
      &:nth-child(5) {
        grid-area: 3 / 1 / 4 / 3;
      }
      &:nth-child(6) {
        grid-area: 3 / 3 / 4 / 7;
      }
      &:nth-child(7) {
        grid-area: 4 / 1 / 5 / 7;
      }
      &:last-child {
        grid-area: 5 / 1 / 6 / 7;
      }
    }
  }

  &__error {
    grid-column: span 6;
    font-size: 14px;
    line-height: 18px;
  }

  &__create-button {
    margin-top: 16px;
  }
}

@media screen and (max-width: 768px) {
  .modal-register {
    width: 100%;
    padding: 24px 28px;

    &__form-container {
      &__small-width {
        label {
          &:first-child {
            width: 50%;
            max-width: 50%;
          }

          &:last-child {
            width: 50%;
          }
        }
      }

      &__half-width {
        label {
          width: 50%;
        }
      }
    }
  }
}
