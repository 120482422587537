@import '../../styles/_variables';

.modal-enter-code {
  padding: 24px 32px 40px;

  &__phone-number {
    margin-left: 44px;
  }

  &__info {
    white-space: pre-wrap;
    font-size: 14px;
    line-height: 18px;
    color: $black-64;
    margin-bottom: 16px;
  }

  &__enter-code-container {
    display: flex;
    gap: 8px;
  }

  &__enter-code-input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    color: transparent;
    border: none;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  &__enter-code-number {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 40px;
    border: 1px solid $black-16;
    border-radius: 8px;
    font-size: 16px;
    line-height: 20px;
  }

  &__enter-code-number-active {
    border: 2px solid $extra-blue;
    border-radius: 8px;
  }

  &__enter-code-number-error {
    border: 2px solid $red-rufous-base;
    border-radius: 8px;
  }

  &__restart-call-button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    background: transparent;
    cursor: pointer;
    color: $black-64;
    border: none;
    margin-left: 8px;
  }
}
