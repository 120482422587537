@import '../../../styles/_variables';

.accruals-container {
  &__calendar-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    &__separator {
      width: 8px;
      height: 2px;
      background: $black-72;
      border: none;
      margin-top: 20px;
    }
  }

  &__header {
    padding: 18px 0 24px 0;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(6, auto);

    &__first-cell {
      padding-left: 18px;
    }

    &__header {
      padding-bottom: 8px;
      border-bottom: 1px solid $black-16;

      &:first-child {
        padding-left: 18px;
      }

      &:nth-child(5) {
        text-align: center;
      }
    }

    &__tooltip {
      width: fit-content;
      margin-left: -12px;
      transform: translate(50%);
    }

    &__row {
      display: contents;
      background: green;
      vertical-align: middle;

      div {
        display: flex;
        align-items: center;
        padding: 16px 0;
        border-bottom: 1px solid $black-16;

        &:first-child {
          padding-left: 18px;
        }

        &:nth-child(5) {
          justify-content: flex-end;
          padding-right: 20%;
        }
      }

      &:hover div {
        background: $black-8;
        border-bottom: 1px solid transparent;

        &:first-child {
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }

      &__name {
        display: flex;
        flex-direction: column;
        align-items: initial !important;
        gap: 8px;

        span {
          &:first-child {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      &__grey-text {
        font-size: 14px;
        line-height: 16px;
        color: $black-64;
      }

      &__sum {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  &__filter-button {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &__modal {
    &__close-button {
      top: 24px;
      right: 24px;
    }

    &__header {
      height: 80px;
      border-bottom: 1px solid $black-16;
      padding: 30px 24px;

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
      }
    }

    &__body {
      padding: 16px 24px;
    }

    &__calendar {
      justify-content: space-between;
    }
  }
}

@media screen and (max-width: 768px) {
  .accruals-container {
    &__header {
      flex-direction: row-reverse;
      padding-top: 6px;
    }

    &__body {
      grid-template-columns: repeat(4, auto);

      &__header {
        font-size: 12px;
        line-height: 16px;

        &__sum {
          text-align: right;
          padding-right: 16px;
        }
      }

      &__row,
      &__row__sum,
      &__row__name span:first-child {
        font-size: 12px;
        line-height: 14px;
      }

      &__row {
        div {
          &:last-child {
            align-items: flex-end;
          }
        }

        &__sum {
          display: flex;
          flex-direction: column;
          gap: 6px;
          align-self: flex-end;
          height: 100%;

          span {
            padding-right: 16px;
          }
        }

        &__line {
          width: 1px;
          background-color: $black-32;
          height: 12px;
          border: none;
        }

        &__grey-text {
          font-size: 12px;
          line-height: 14px;
          color: $black-64;
          font-weight: 400;
        }
      }
    }
  }
}
