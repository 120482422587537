@import '../../../styles/_variables';

.analysis-card {
  border-radius: 8px;
  margin-bottom: 8px;
  border: 1px solid $black-16;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 6px 24px;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__open-header {
    border-bottom: 1px solid $black-16;
  }

  &__header-title-name {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__body {
    padding: 16px 24px;

    &-description {
      font-size: 14px;
      line-height: 22px;
      color: $black-72;
    }

    &-table {
      padding: 16px 0;
    }
  }

  &__tooltip {
    top: 42px;
    right: 24px;
  }
}
