@import '../../styles/_variables';

.list-item {
  border-bottom: 1px solid $black-16;
  padding: 8px 0;
  margin-right: 12px;

  &:hover {
    border-radius: 8px;
    background: $black-8;
    border-bottom: 1px solid transparent;
  }

  &:nth-child(n) {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  &__text-bold {
    font-weight: 700;
  }

  &__button {
    border: 2px solid $extra-blue;
    border-radius: 40px;
    background: transparent;
    color: $extra-blue;
    cursor: pointer;
    padding: 0;
    width: 106px;
    height: 36px;
    font-size: 16px;

    &:hover {
      background: $extra-blue;
      color: $clear-white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.08);
    }
  }

  &__mobile-button {
    border: none;
    padding: 0 10px 0 0;

    &:hover {
      background: transparent !important;
      color: $extra-blue !important;
      box-shadow: none !important;

      svg {
        fill: $extra-blue;
      }
    }
  }

  &__mobile-button-contain {
    position: relative;
    border: none;

    svg {
      fill: $extra-blue;
    }

    &:after {
      content: '+1';
      position: absolute;
      display: block;
      color: $clear-white;
      top: 9px;
      left: 10px;
      font-size: 9px;
      margin-left: auto;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
    }
  }
}

.button.contain {
  padding: 0;
  width: 106px;
}
