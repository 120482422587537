@import '../../styles/_variables';
@import '../../styles/_mixins';

.modal-supplement-info {
  position: fixed;
  background-color: $black-32;
  z-index: 2500;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    position: relative;
    display: flex;
    background: $clear-white;
    margin: auto;
    box-shadow: 0 22.3363px 17.869px $black-4, 0 12.5216px 8px $black-8,
      0 6px 8px $black-4, 0 2px 2px $black-8;
    border-radius: 8px;
    width: 1120px;
    height: 640px;
    max-height: 640px;

    &__image-container {
      width: 480px;
      overflow: hidden;
      border-radius: 8px 0 0 8px;

      img {
        width: auto;
        height: 100%;
        margin-left: -50%;
        margin-right: auto;
      }
    }

    &__body {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 640px;
      padding: 48px 12px 48px 40px;
    }

    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
    }

    &__info-container {
      display: flex;
      flex-direction: column;
      gap: 32px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 8px;

      @include scrollbar();
    }

    &__facts-table-block {
      div {
        overflow-x: hidden;
      }
    }

    &__info-block {
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__text {
        font-size: 16px;
        line-height: 24px;
        color: $black-87;
      }

      &__title {
        font-size: 18px;
        line-height: 21px;
        color: $black-64;
      }

      table {
        thead {
          tr {
            font-size: 14px;
            line-height: 16px;
            color: $black-64;
            text-align: left;
          }
        }

        tbody {
          tr {
            font-size: 16px;
            line-height: 24px;
            color: $black-87;
          }
        }
      }
    }

    &__close-btn {
      position: absolute;
      right: 16px;
      top: 16px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: none;
      background-color: transparent;
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
  .modal-supplement-info {
    display: block;
    background-color: $clear-white;
    border-right: 0;

    &-content {
      flex-direction: column;
      box-shadow: none;
      border-radius: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;

      &__image-container {
        height: 230px;
        width: 100%;
        overflow: initial;
        border-radius: 0;

        img {
          width: calc(100% + 48px);
          height: 100%;
          margin: 0 -24px;
          object-fit: cover;
        }
      }

      &__info-block {
        &__text {
          font-size: 14px;
          line-height: 20px;
        }

        table {
          tbody {
            tr {
              font-size: 14px;
              line-height: 16px;
            }
          }
        }
      }

      &__body {
        gap: 24px;
        width: 100%;
        padding: 24px;
      }

      &__title {
        font-size: 20px;
        line-height: 24px;
      }

      &__info-container {
        gap: 24px;
        overflow: initial;
        padding-right: 0;
      }

      &__close-btn {
        right: 24px;
        top: 24px;
      }
    }
  }
}
