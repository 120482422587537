@import 'fonts';
@import 'variables';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
}

main {
  display: flex;
  overflow-y: hidden;
}

.main-mobile {
  display: flex;
  overflow-y: hidden;
}

@media screen and (max-width: 767px) {
  main {
    position: relative;
    flex-direction: column;
    overflow-y: initial;
    height: 100vh;
  }
}
