@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.analysis-study-card {
  &__content-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 10px 0;

    &__name {
      font-size: 16px;
      line-height: 20px;
      text-decoration-line: underline;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      cursor: pointer;
      width: 100%;
      display: inline-block;
    }

    &__ammunition-name {
      max-width: 100%;
    }

    &__info-icon {
      color: $black-64;
      width: 16px;
      cursor: pointer;
      margin-right: 8px;
      flex-shrink: 0;
      height: 24px;
      border: none;
      background: transparent;
    }

    &__type-name {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      color: $black-72;
      white-space: nowrap;
      margin-right: 8px;

      img {
        filter: opacity(0.48);
      }
    }

    &__price {
      font-size: 16px;
      line-height: 20px;
      margin-left: auto;
      margin-right: 16px;
      white-space: nowrap;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__title-price {
    margin-left: auto;
    font-size: 14px;
    line-height: 16px;
    color: $black-72;
    margin-right: 8px;
  }

  &__trash,
  &__content-item__delete-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $red-rufous-base;
    height: 24px;
  }

  &__header {
    border-bottom: 1px solid $black-16;
  }

  &__name-opened {
    white-space: initial;
  }
}

@media screen and (max-width: 768px) {
  .analysis-study-card {
    &__title {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__title-price {
      white-space: nowrap;
    }

    &__modal {
      margin-top: 48px;
    }
  }
}
