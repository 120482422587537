@import '../../styles/_variables';

.spinner-container {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: $clear-white;
  z-index: 2;
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:after {
    content: ' ';
    display: block;
    width: 32px;
    height: 32px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $extra-blue;
    border-color: $extra-blue transparent $extra-blue transparent;
    animation: spinner 1.2s linear infinite;
  }
}

.spinner-full-screen {
  position: absolute;
  left: 0;
}

.spinner-transparent-bg {
  background: transparent;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
