@import '../../../styles/_variables';
@import '../../../styles/_mixins';

.analysis-entity-card {
  display: flex;
  flex-direction: column;
  gap: 16px;

  background: $clear-white;
  border-radius: 8px;
  box-shadow: 0 4px 4px $black-16;
  padding: 12px 16px;

  &:hover {
    cursor: pointer;
    background: $black-8;
    box-shadow: 0 4px 4px $black-16;
  }

  &__group-name {
    font-size: 16px;
    line-height: 20px;
  }

  &__group-count {
    font-size: 14px;
    line-height: 16px;
    color: $black-48;
  }

  &__group-in-order {
    font-size: 14px;
    line-height: 16px;
    margin-left: auto;
    color: $green-pantone-base;
    white-space: nowrap;
  }

  &__name-block,
  &__count-block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__group-name {
      font-size: 16px;
      line-height: 20px;
    }

    &__group-count {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      color: $black-72;
      white-space: nowrap;

      img {
        filter: opacity(0.48);
      }
    }
  }

  &__count-block {
    &__group-count {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      color: $black-87;
    }
  }
}
